<template>
    <modal-lateral ref="modalInformacionLeechero" :titulo="`Información  del ${$config.vendedor}`">
        <div class="f-15 px-4">
            <cargando v-if="cargando" />
            <div v-for="data in infoLeechero" :key="data.id">
                <div class="row mx-0 mt-3 justify-center">
                    <img v-if="data.logo_mini_firmado===null" class="br-10 p-2 border bg-whitesmoke" width="150" height="150" src="/img/no-imagen/sin_cliente.svg" alt="" />
                    <img v-else :src="data.logo_mini_firmado" class="border br-12" style="object-fit:cover;width:150px;height:150px;" alt="" />
                </div>
                <div class="row mx-0 mt-2 justify-center text-general f-20 f-600">
                    <span>{{ data.propietario_nombre }}</span>
                </div>
                <div class="row mx-0 mt-3">
                    <span class="text-general f-600">Número de celular:</span><span class="ml-1">{{ data.telefono_celular || 'No registra' }}</span>
                </div>
                <div class="row mx-0 mt-2">
                    <span class="text-general f-600">Correo electrónico:</span><span class="ml-1">{{ data.correo || 'No registra' }} </span>
                </div>
                <div class="row mx-0 mt-2">
                    <span class="text-general f-600">Cedi:</span>
                    <span class="ml-1">{{ data.nombre_cedis || 'No registra' }} </span>
                </div>
                <div class="row mx-0 mt-2">
                    <span class="text-general">Cedi:</span><span class="ml-1">{{ data.nombre_cedis || 'No registra' }} </span>
                </div>
                <div class="row mx-0 mt-4">
                    <span class="mr-2 text-general">
                        {{ data.pais || 'No registra' }},
                    </span>
                    <p>{{ data.ciudad || 'No registra' }}</p>
                </div>
                <div class="row mx-0 mt-2">
                    <span class="mr-2 text-general f-600">
                        Dirección:
                    </span>
                    <p> {{ data.direccion || 'No registra' }}</p>
                </div>
                <div class="row mx-0 mt-2">
                    <span class="mr-2 text-general f-600">
                        Complemento:
                    </span>
                    <p>{{ data.direccion_anexo || 'No registra' }}</p>
                </div>
                <div class="row mx-0 mt-2">
                    <span class="mr-2 text-general f-600">
                        Barrio / Colonia:
                    </span>
                    <p> {{ data.barrio || 'No registra' }}</p>
                </div>
                <div class="row mx-0 mt-2">
                    <span class="mr-2 text-general f-600">
                        Descripción:
                    </span>
                    <p> {{ data.descripcion || 'No registra' }}</p>
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
export default {
    data(){
        return {
            cargando: true,
            infoLeechero: [],
        }
    },
    methods: {
        toggle(id_detalle, infoLeechero){
            this.infoLeechero = infoLeechero;
            this.cargando = false;
            this.$refs.modalInformacionLeechero.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
